#home {
    height: 90vh;
    height: 100%;
    background: url(../img/main.jpg) no-repeat center center; 
    -webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
    display: flex;
    flex-direction: column;
}

.header-text-container{
    height: 100%;
    margin: 10% 10% 10% 10%;
    background-color: rgba($color: #000000, $alpha: 0.6);
}

.header-logo-container{
    display: flex;
    justify-content: center;
}
.header-logo{
    content:url(../img/logo-w.png);
    margin-top: 40px;
    width: 10%;
    flex: 0 1 300px;
}

.intro-features{
    font-size: medium;
    color: rgb(180, 180, 180);
    line-height: 2em;
    text-align: left;
    padding: 10%;
}