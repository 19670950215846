#location {
    background-color: #302729;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.location-container{
    display:flex;
    flex-direction: column;
    flex: 1 1 400px;
    padding: 5%;
    margin: 10px;    
}
.location-container-sub{
    width:  100%;
    display: flex;
    flex-direction: row;
    flex: 1 1 100px;
    flex-wrap: wrap;
}
.location-map-btn-container{
    flex: 1 1 100px;
    min-width: 170px;
    text-align: center;
}

.map-image{
    width: 100%;
    min-width: 280px;
    flex: 1 1 100px;    
    content:url(../img/map.png);
}

.map-btn{
    margin-top: 1em;
    margin-bottom: 1em;
    background-color: rgba(0,0,0,0);
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #F3DA7B;
    border-radius: 50px 50px 50px 50px;
    padding: 20px 38px 20px 38px;
    color: ivory;
    display: inline-block;
    line-height: 1;
    font-size: medium;
    text-decoration: none;
}

.map-btn:hover {
    background-color: rgb(226, 148, 148);
}



.loc-text-title1{
    font-size: xx-large;
    font-weight: bold;
    color: ivory;
    line-height: 2em;
}
.loc-text-title2{
    font-size: large;
    font-weight: bold;
    color: ivory;
    line-height: 2em;
    padding-top: 5%;
    text-decoration: underline;
}
.loc-text1{
    font-size: large;
    color: rgb(180, 180, 180);
    line-height: 2em;
}

.loc-text2{
    font-size: large;
    color: rgb(180, 180, 180);
    line-height: 2em;
}
