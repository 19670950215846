#footer{
    background-color: #302729;
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}
.footer-container{
    padding: 5%;
}
.footer-container2{
    background-color: #1b1b1b;
    padding: 5%;
    text-align: center;
}
.footer-container-sns{    
    font-size:  xx-large;
    font-weight: bold;
    color: ivory;
    padding: 5%;
    display:flex;
    flex-direction: row;
    justify-content: center;
}
.footer-sns-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 3em;
    border-radius: 3em;
}
.footer-sns-btn a{
    text-decoration: none;
    color: ivory;
}

.footer-sns-btn:hover {
    background-color: rgb(226, 148, 148);
}

.footer-text-title{
    font-size: xx-large;
    font-weight: bold;
    color: ivory;
    line-height: 2em;
}

.footer-text1{
    font-size: large;
    color: rgb(180, 180, 180);
    line-height: 2em;
}

.footer-text1 a{
    text-decoration: none;
    color: rgb(180, 180, 180);
}

.footer-text2{
    font-size: small;
    color: rgb(180, 180, 180);
    line-height: 2em;
}